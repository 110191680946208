<template>
    <div class="space-y-4 px-2 max-md:px-1 mb-6">
        <div class="flex justify-center mt-2 mb-6 px-2">
            <div class="inline-flex px-2 flex-wrap max-md:w-full max-md:flex-wrap max-md:overflow-x-auto gap-1 bg-gray-100/50 p-1 rounded-xl">
                <button
                    v-for="tab in tabs"
                    :key="tab.key"
                    @click="handleTabClick(tab)"
                    :class="[
                        'px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 flex items-center gap-2 whitespace-nowrap',
                        'max-md:flex-grow max-md:justify-center max-md:w-full',
                        selectedTab === tab.key
                            ? 'bg-white text-gray-900 shadow-sm scale-[1.02] ring-1 ring-gray-200'
                            : 'text-gray-600 hover:bg-white/50 hover:text-gray-900'
                    ]"
                    :aria-pressed="selectedTab === tab.key"
                >
                    <span class="max-md:text-xs">{{ tab.label }}</span>
                    <span
                        v-if="articles[tab.key]?.meta?.total"
                        :class="[
                            'text-xs px-1 py-0.5 rounded-full font-medium transition-colors max-md:text-[10px] max-md:px-1.5',
                            selectedTab === tab.key
                                ? 'bg-main/10 text-main'
                                : 'bg-gray-200 text-gray-600'
                        ]"
                    >
                        {{ articles[tab.key].meta?.total }}
                    </span>
                </button>
            </div>
        </div>

        <!-- Tab Content -->
        <div v-if="!initialLoading || loadingMore">
            <div v-if="articles[selectedTab]?.data">
                <LazyArticlesList
                    :articles="articles[selectedTab]"
                />
            </div>

            <!-- Load More Button -->
            <div v-if="canLoadMore" class="mt-6 text-center">
                <button
                    @click="loadMore"
                    :disabled="loadingMore"
                    class="bg-white text-main hover:bg-main hover:text-white px-6 py-2 rounded-lg border border-main transition-colors duration-200 text-sm font-medium disabled:opacity-50"
                >
                    {{ loadingMore ? 'Se încarcă...' : 'Încarcă mai multe' }}
                </button>
            </div>
        </div>
        <QueryTimer v-if="initialLoading" />
    </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import LazyArticlesList from "@/Components/LazyArticleList.vue";
import QueryTimer from "@/Components/QueryTimer.vue";

export default {
    name: 'TrendingArticlesTabs',
    components: {
        QueryTimer,
        LazyArticlesList,
        LoadingSpinner
    },
    props: {
        title: {
            type: String,
            default: 'Articole'
        },
        filters: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            selectedTab: 'day',
            initialLoading: false,
            loadingMore: false,
            articles: {
                day: null,
                week: null,
                all: null
            },
            pagination: {
                day: {currentPage: 1, lastPage: null},
                week: {currentPage: 1, lastPage: null},
                all: {currentPage: 1, lastPage: null}
            },
            tabs: [
                {key: 'day', label: 'Articolele Zilei', endpoint: '/api/articles/day'},
                {key: 'week', label: 'Articolele Săptămânii', endpoint: '/api/articles/week'},
                {key: 'all', label: 'Toate Articolele', endpoint: '/api/articles'}
            ],
            lastAppliedFilters: {}
        }
    },
    computed: {
        canLoadMore() {
            const pagination = this.pagination[this.selectedTab];
            return pagination && pagination.currentPage < pagination.lastPage;
        },
        currentFilters() {
            return {
                ...this.filters
            };
        },
        filtersHaveChanged() {
            return JSON.stringify(this.currentFilters) !== JSON.stringify(this.lastAppliedFilters[this.selectedTab] || {});
        }
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.resetAndFetchCurrentTab();
            }
        }
    },
    async created() {
        await this.loadInitialTabs();
    },
    methods: {
        async loadInitialTabs() {
            this.initialLoading = true;
            try {
                // Load tabs sequentially with initial filters
                for (const tab of this.tabs) {
                    await this.fetchArticlesForTab(tab.key, 1, false, true);
                    // Store initial filters state for each tab
                    this.lastAppliedFilters[tab.key] = {...this.currentFilters};
                }
            } catch (error) {
                console.error('Failed to load initial tabs:', error);
            } finally {
                this.initialLoading = false;
            }
        },

        resetAndFetchCurrentTab() {
            // Reset pagination for current tab only
            this.pagination[this.selectedTab] = {
                currentPage: 1,
                lastPage: null
            };

            // Reset articles for current tab only
            this.articles[this.selectedTab] = null;

            // Fetch articles for current tab and update last applied filters
            this.fetchArticlesForTab(this.selectedTab);
            this.lastAppliedFilters[this.selectedTab] = {...this.currentFilters};
        },

        async fetchArticlesForTab(period, page = 1, append = false, silent = false) {
            if (this.articles[period]?.data && page === 1 && !append && !this.filtersHaveChanged) {
                return;
            }

            if (!silent && this.loading && !this.loadingMore) {
                return;
            }

            try {
                if (!silent) {
                    if (append) {
                        this.loadingMore = true;
                    } else {
                        this.initialLoading = true;
                    }
                }

                const tab = this.tabs.find(t => t.key === period);
                if (!tab) {
                    console.error(`No tab found for period: ${period}`);
                    return;
                }

                const response = await axios.get(tab.endpoint, {
                    params: {
                        page,
                        ...this.currentFilters
                    }
                });

                if (response.data && response.data.data) {
                    const newArticles = response.data.data;

                    if (append && this.articles[period]?.data) {
                        this.articles[period].data = [...this.articles[period].data, ...newArticles];
                        this.articles[period].meta = response.data.meta;
                    } else {
                        this.articles[period] = {
                            data: newArticles,
                            meta: response.data.meta
                        };
                    }

                    this.pagination[period] = {
                        currentPage: response.data.meta.current_page,
                        lastPage: response.data.meta.last_page
                    };

                    // Update last applied filters after successful fetch
                    this.lastAppliedFilters[period] = {...this.currentFilters};
                }
            } catch (error) {
                console.error(`Failed to fetch ${period} articles:`, error);
            } finally {
                if (!silent) {
                    this.initialLoading = false;
                    this.loadingMore = false;
                }
            }
        },

        async handleTabClick(tab) {
            if (window.gtag) {
                window.gtag('event', 'tab_change', {
                    'event_category': 'Article Navigation',
                    'event_label': tab.label,
                    'tab_key': tab.key
                });
            }

            this.selectedTab = tab.key;

            // Check if we need to apply new filters for this tab
            if (this.filtersHaveChanged) {
                this.resetAndFetchCurrentTab();
            }
        },

        async loadMore() {
            const currentPagination = this.pagination[this.selectedTab];
            if (!currentPagination || currentPagination.currentPage >= currentPagination.lastPage) {
                return;
            }

            const nextPage = currentPagination.currentPage + 1;
            await this.fetchArticlesForTab(this.selectedTab, nextPage, true);
        }
    }
}
</script>
